import React from "react";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image"
import Layout from "../components/layout";
import SEO from "../components/seo";
import icon from "../images/icon-benefits.svg";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';



const Benefits = ({ data }) => {
  const Benefits = data.allContentfulBenefit.edges;
  return (
    <Layout>
      <SEO title="Lawtech Hub Benefits" />
      <div className="">
        <Img
          className="object-cover w-full h-64"
          fluid={data.file.childImageSharp.fixed}
          alt="Header image"
        />
      </div>
      <div className="container">
      <h1 className="title">Benefits</h1>
      </div>
      <div className="bg-white w-full border-t-2 border-b border-white">
        <div className="container flex py-6 my-2 self-center">
          <div className="w-auto lg:w-1/2 self-center">
            <h3 className="pt-6 text-center md:text-left">
              {documentToReactComponents(data.contentfulPageIntro.intro.json)}
            </h3>
          </div>
          <div className="w-1/2 hidden lg:block">
            <img src={icon} className="h-64 mb-0 float-right mx-12" alt="LawTech Hub Icons" />
          </div>
        </div>
      </div>




      <div className="container mt-12 mb-32">


    <div className="w-full text-center text-purple-800 font-bold uppercase">Take advantage of over $550,000 worth of business support</div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 -mx-2 mt-24">
          {Benefits.map(({ node: post }) => (


            <Link to={`/benefits/${post.slug}`} key={post.id} className="hover-card flex bg-white shadow-sm mb-12 mx-2 rounded-sm" alt="{post.serviceType}">

              <div className="relative px-8 pb-8 pt-10 text-xs text-center leading-4 w-full">

                <div className="relative mx-auto h-24 w-24 -mt-20 border-gray-100 flex  rounded-full border-4 bg-white justify-center items-center">
                <Img
                    className="logo block"
                    fixed={post.logo.fixed}
                    title={post.name}
                    alt={post.name}

                  />  

                </div>
                <div className="text-sm font-extrabold text-purple-800 mt-4 mb-2">{post.name}</div>
                <div className="text-black my-2">{post.shortDescription.shortDescription}</div>
                <div className="my-8 leading-5"                   

              />
              </div>
            </Link>




          ))}
        </div>
      </div>



    </Layout>
  );
};
export default Benefits;



export const query = graphql`
  query benefitsIndexPageQuery {
    contentfulPageIntro(page: { eq: "Benefits" }) {
      intro {
        json
      } 
    }

    allContentfulBenefit(sort: {fields: name, order: ASC}) {
      edges {
        node {
          id
          slug
          name
          serviceType
          logo {
            fixed(width: 70) {
              base64
              src
              srcSet
              width
              height
            }
          }
          shortDescription {
            shortDescription
          }
        }
      }
    }

    file(relativePath: { eq: "banner-benefits.jpg" }) {
      childImageSharp {
        fixed(width: 1200) {
          base64
          width
          height
          src
          srcSet
        }
      }
    }    
  }



`;